:root {
  --text-color: white;
  --icon-primary-color: #333;
}

html,
body {
  margin: 0;
  overflow: hidden;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  background-color: black;
  box-sizing: border-box;
  font-weight: 300;
}

.App {
  width: 100vw;
  height: 100vh;
}

#main-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  user-select: none;
}

#main-content-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#main-content-container h1 {
  text-align: center;
  background: #80F1F1;
  background: linear-gradient(to right, #80F1F1 0%, #7F7FFF 50%, #FF88FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#main-content-container .row {
  background-color: rgba(0, 0, 0, 0.4);
  text-align: justify;
  align-self: flex-start;
  color: var(--text-color, Canvas);
  padding: 2em;
  margin: 0;
  margin-left: 16%;
  max-width: 602px;
  border-radius: 8px;
}

#main-content-container .row a,
#main-content-container .row span {
  color: var(--text-color, Canvas);
  font-weight: bold;
}

#main-content-container .row a {
  text-decoration: underline;
}

#source-code-link {
  position: absolute;
  right: 0;
  bottom: 0;
  color: var(--text-color, Canvas);
  margin: 0.5rem 0.5rem;
  text-align: center;
}

#source-code-link a {
  color: gold;
}

.contact-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0;
  padding: 0;
  margin-top: 1rem;
  justify-content: center;
}

.contact-list-item {

  flex: 0 0 50px;
  fill: #d4d4d4;
  height: 55px;
  width: 55px;
  list-style: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all .2s;
}

.contact-list-item:hover {
  background-color: var(--icon-primary-color);
}

.contact-icon {
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  width: 100%;
  outline: none;
  padding: 0.625rem;
  background-image: linear-gradient(to right, #7F7FFF 0%, #FF88FF 100%);
  mask-size: contain;
}


.contact-list-item:hover .contact-icon {
  filter: brightness(1.5);
}

.contact-icon.github {
  mask: url('/public/assets/github.svg') no-repeat center / 65%;
  -webkit-mask: url('/public/assets/github.svg') no-repeat center / 65%;
}

.contact-icon.email {
  mask: url('/public/assets/gmail.svg') no-repeat center / 65%;
  -webkit-mask: url('/public/assets/gmail.svg') no-repeat center / 65%;
}

.contact-icon.linkedin {
  mask: url('/public/assets/linkedin.svg') no-repeat center / 65%;
  -webkit-mask: url('/public/assets/linkedin.svg') no-repeat center / 65%;
}

@media screen and (max-width: 768px) {
  #main-content {
    width: 100%;
  }

  #main-content-container .row {
    text-align: left;
    margin: 0 8%;
  }

  #source-code-link {
    margin: 1rem 0;
    right: 50%;
    transform: translateX(50%);
  }

  .contact-list {
    justify-content: center;
  }
}